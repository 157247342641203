import React from "react";
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from "gatsby";

import "@assets/scss/App.scss";

import Layout from "@components/layout";
import Header from "@components/header/Header";
import BackToolbar from "@components/modules/back-toolbar/";
import ContactTeam from "@components/forms/contact-member/";

import ImageTextModule from "@components/modules/image-text/";
import PopularSearch from "@components/popular-search/index";
import Footer from "@components/footer/";
import SEO from "@components/seo";

const ContactMemberPage = ({location}, props) => {
    const data = useStaticQuery(graphql`
        {
            file(relativePath: { eq: "Candidate-Refer-A-Candidate-upload-CV.png" }) {
                childImageSharp {
                    fluid(quality: 80, maxWidth: 800) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                }
            }
        }
    `)
    return(
        <Layout>
            <SEO
                title={`Contact ${location.state ? location.state.title : 'our Team Member'} | Prop Tech and Estate Agent Jobs`}
                // description="If you know of someone looking for PropTech & estate agents jobs in the UK, why not refer them to us? Crux Careers is a leader in PropTech & estate agency recruitment."
            />
            <Helmet 
                bodyAttributes={{
                    class: 'grey-bg'
                }}
            />
            <Header />
            <BackToolbar
                backLinkLabel="Back"
                showCreateAlert={false}    
            />
            <ContactTeam
                title={location.state ? location.state.title : 'Contact Member'}
                staffName={location.state ? location.state.title : ''}
                staffEmail={location.state ? location.state.email : ''}
                intro=""
                formClass=" form-dark mb-34 mb-md-51 mb-xl-0"
            />
            <ImageTextModule
                imgUrl={data.file.childImageSharp.fluid}
                moduleData={
                    [
                        {
                            id: 1,
                            imageUrl: 'young-woman.jpg',
                            videoUrl: '',
                            Title: '',
                            Text: '',
                            Buttons: [
                                {
                                    id: '123213',
                                    Label: 'Upload Your CV',
                                    externalLink: null,
                                    page: null,
                                    targetLink: '/candidates/upload-your-cv'
                                }
                            ]
                        }
                    ]
                }
            />
            <PopularSearch {...props}
                sectionClass=" section-grey-bg"
            />
            <Footer />
        </Layout>
    )
}

export default ContactMemberPage
